import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import constants from '../../utils/constants';
import Divider from '../common/divider';

export default () => {
    const data = useStaticQuery(query);

    const imageSrc = data.image.childImageSharp.fluid.src;

    return (
        <>
            <section id="dostavki-address">

                <Container className="py-5 mt-5 text-center">
                    <h1>За нас</h1>
                    <Divider addClassName="mb-5" />

                    <Row className="my-5">
                        <Col lg={6} className="text-left">
                            <h3>Нашата история</h3>

                            <Image src={imageSrc} fluid alt="Нашата история" className="d-lg-none my-4" />
                            <p>
                                "Ключарите ООД"" е компания, насочена изцяло към предлагането на ключарски и автоключарски услуги, както и едни от най-добрите съвременни заключващи устройства.
                                Нашият ключарски център е специализиран в:
                            </p>
                            <ul className="key-list house">
                                <li>изработка на всякакви видове ключове</li>
                                <li>изработка на битови ключове</li>
                                <li>изработка на автомобилни ключове с чип</li>
                                <li>изработка на RFID чипове за входни врати</li>
                                <li>ремонт на заключващи механизми</li>
                                <li>отключване на автомобили</li>
                                <li>отключване на домове</li>
                                <li>отключване на каси и сейфове</li>
                                <li>смяна секретност на брави, патрони и др.</li>
                                <li>продажба на автомобилни и битови патрони и брави.</li>
                            </ul>
                            <p>
                                Като професионалисти с дългогодишна практика в ключарските услуги, е изключително важно да бъдем максимално достъпни за своите редовни и бъдещи клиенти.<br />При спешни и нетърпящи отлагане ситуации, можете да се свържете с нашия екип на посочените телефони.<br />В случай, че предпочитате да ни посетите на място, сме предоставили актуална карта с местоположението на ключарското ни ателие. Можете да ни откриете и в социалните мрежи – не забравяйте да харесате нашата <a href={constants.FOOTER.FACEBOOK} target="_blank" rel="noreferrer nofollow" className="text-gold">Facebook страница</a>.
                            </p>

                            <Link to={'/kontakti'} className="btn btn-primary mt-3">Свържи се с нас</Link>
                        </Col>

                        <Col lg={6} className="d-none d-lg-flex justify-content-end">
                            <Image src={imageSrc} fluid alt="Нашата история" className="index-image" />
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    )
}

const query = graphql`
    query AboutHero {
        image: file(relativePath: {eq: "businesses/clients.jpeg"}) {
            childImageSharp {
                fluid(quality: 60, maxHeight: 450) {
                    src
                }
            }
        }
    }
`;
