import React from "react"
import LeftText from "../components/about/left-text"
import ListOfBusinesses from "../components/common/ListOfBusinesses"
import Layout from "../components/site-wide/layout/layout"
import constants from "../utils/constants"

const AboutPage = () => (
    <Layout
        title={constants.ABOUT.META.TITLE}
    >
        <LeftText/>

        <ListOfBusinesses/>
    </Layout>
)

export default AboutPage
